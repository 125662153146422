import React, { useRef } from "react";
import Clients from "../components/Clients";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Benefits from "../components/Benefits";
import Services from "../components/Services";
import BannerSlider from "../components/BannerSlider";

const Home = (props) => {
  const eligibilitySectionRef = useRef(null);
  return (
    <>
      <BannerSlider />
      <Hero ServicesRef={props.ServicesRef} />
      <Intro eligibilitySectionRef={eligibilitySectionRef} />
      <Services
        id="services" // Add id attribute here
        ServicesRef={props.ServicesRef}
        fthRef={props.fthRef}
        iwiRef={props.iwiRef}
        ewiRef={props.ewiRef}
        ncbrRef={props.ncbrRef}
        arhpRef={props.arhpRef}
        uiRef={props.uiRef}
        liRef={props.liRef}
      />
      <Benefits eligibilitySectionRef={eligibilitySectionRef} />
      <Clients />
      {/* <Cta/> */}
    </>
  );
};

export default Home;
