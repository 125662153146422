import React from "react";
import { Link, NavLink } from "react-router-dom";

const NavLinks = ({ ServicesRef }) => {
  return (
    <>
      <NavLink
        className="px-4 font-extrabold text-[#000000]-500 hover:text-orange-800"
        smooth
        to="/aboutus"
      >
        About Us
      </NavLink>
      {/* <NavLink
        onClick={() =>
          ServicesRef.current.scrollIntoView({
            behavior: "smooth",
          })
        }
        className="px-4 font-extrabold text-[#000000] hover:text-orange-800"
        smooth
        to="#services"
      >
        Services
      </NavLink> */}
      <NavLink
        onClick={() =>
          ServicesRef.current.scrollIntoView({
            behavior: "smooth",
          })
        }
        className="px-4 font-extrabold text-[#000000] hover:text-orange-800"
        smooth
        to="/contactus"
      >
        Contact
      </NavLink>

      <NavLink
        className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth
        to="/eco4"
      >
        Apply Now
      </NavLink>
    </>
  );
};

export default NavLinks;
