import React, { useState } from "react";
import { Form, Input, Button, DatePicker, message, Select, Upload } from "antd";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "./Firebase";
import NavBar from "./Navbar/NavBar";

const { Option } = Select;

const Eco4 = () => {
  const [showOtherField, setShowOtherField] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // State to hold selected file
  const [imageUrl, setImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onFinish = async (values) => {
  try {
    const db = getFirestore(app);
    const createdAt = new Date(); // Get the current date and time
    const isRead = false; // Set isRead to false

    if (values.dateofBirth) {
      values.dateofBirth = values.dateofBirth.toDate();
    } else {
      delete values.dateofBirth;
    }

    if (imageUrl) {
      values.imageUrl = imageUrl;
    }

    // Include isRead and createdAt in the values object
    values.isRead = isRead;
    values.createdAt = createdAt;

    const docRef = await addDoc(collection(db, "userDetails"), values);
    console.log("Document ID:", docRef.id);
    message.success("Form submitted successfully!");
    console.log("Form submitted successfully!");

    // Upload file if selectedFile is not null
    if (selectedFile) {
      await handleImageUpload(selectedFile);
    }
  } catch (error) {
    console.error(error);
    message.error("Failed to submit form.");
    console.error("Failed to submit form.");
  }
};


  const handleBenefitChange = (value) => {
    if (value === "Others") {
      setShowOtherField(true);
    } else {
      setShowOtherField(false);
    }
  };

  const handleImageUpload = async (file) => {
    try {
      if (!file) {
        throw new Error("No file selected");
      }

      setUploading(true);
      const storage = getStorage(app);
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);
      setImageUrl(downloadUrl);
      setUploading(false);

      // Display success message when file upload is successful
      message.success("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploading(false);
      message.error("Failed to upload file.");
    }
  };

  const handleFileChange = (info) => {
    const { file } = info;
    setSelectedFile(file);
  };

  return (
    <>
      <NavBar />
      <div className="eco-container">
        <div className="form-box">
          <div className="formheading">
            <h3>Kindly Fill the form with your details</h3>
          </div>
          <Form name="signup" onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                { required: true, message: "Please enter your full name" },
              ]}
            >
              <Input placeholder="Your Name" />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="number"
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input type="number" placeholder="Phone Number" />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please enter your address" }]}
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
              label="Date of Birth"
              name="dateofBirth"
              rules={[{ required: false, message: "Please select the DOB" }]}
            >
              <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Benefit taken by you"
              name="Benefit"
              rules={[
                { required: true, message: "Please select your Benefit" },
              ]}
            >
              <Select
                placeholder="Select Benefit taken by you"
                onChange={handleBenefitChange}
              >
                <Option value="Income-based Job seeker’s Allowance (JSA)">
                  Income-based Job seeker’s Allowance (JSA)
                </Option>
                <Option value="Income-related Employment & Support Allowance (ESA)">
                  Income-related Employment & Support Allowance (ESA)
                </Option>
                <Option value="Income Support Allowance (ISA)">
                  Income Support Allowance (ISA)
                </Option>
                <Option value="Working Tax Credit (WTC)">
                  Working Tax Credit (WTC)
                </Option>
                <Option value="Child tax credit (CTC)">
                  Child tax credit (CTC)
                </Option>
                <Option value="Universal Credit (UC)">
                  Universal Credit (UC)
                </Option>
                <Option value="Pension savings Credit">
                  Pension savings Credit
                </Option>
                <Option value="Pension Guarantee Credit">
                  Pension Guarantee Credit
                </Option>
                <Option value="Child benefit">Child benefit</Option>
                <Option value="Housing benefit">Housing benefit</Option>
                <Option value="None">None</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>

            {showOtherField && (
              <Form.Item
                label="Other Benefit"
                name="OtherBenefit"
                rules={[
                  {
                    required: true,
                    message: "Please specify the other benefit",
                  },
                ]}
              >
                <Input placeholder="Specify Other Benefit" />
              </Form.Item>
            )}
            <Form.Item label="Upload Image of your Old Boiler">
              <Upload
                beforeUpload={(file) => handleFileChange({ file })}
                showUploadList={false}
              >
                <Button loading={uploading}>
                  {selectedFile ? selectedFile.name : "Click to Upload"}
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              label="Property Ownership or Tenant?"
              name="HouseOwner"
              rules={[{ required: true, message: "Please specify" }]}
            >
              <Select placeholder="Select Property Ownership or Tenant">
                <Option value="Owner">Owner</Option>
                <Option value="Tenant">Tenant</Option>
                <Option value="Council">Council</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="House Type (Banglow,mid-terrace,detached,semi-detached)?"
              name="HouseType"
              rules={[{ required: true, message: "Please specify" }]}
            >
              <Input placeholder="What is your house type?" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="frmbtn">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Eco4;
