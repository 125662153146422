import React from "react";
import { NavLink } from "react-router-dom";
import NavBar from "./Navbar/NavBar";

const PrivacyPolicy = () => {
  return (
    <>
      <NavBar />
      <div className="privacy-policy">
        <h1 className="privacy-policy-heading">Privacy Policy</h1>
        <p className="privacy-policy-paragraph">
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or use this website (the
          "Site"), operated by [Your Organization Name].
        </p>
        <h2 className="privacy-policy-subheading">Information We Collect</h2>
        <p className="privacy-policy-paragraph">
          We may collect the following information when you use the Site:
        </p>
        <ul className="privacy-policy-list">
          <li className="privacy-policy-list-item">
            Personal information you provide, such as your name, email address,
            and phone number (if applicable).
          </li>
        </ul>
        <h2 className="privacy-policy-subheading">
          How We Use Your Information
        </h2>
        <p className="privacy-policy-paragraph">
          We may use the information we collect for the following purposes:
        </p>
        <ul className="privacy-policy-list">
          <li className="privacy-policy-list-item">
            To process and respond to your inquiries about eco4 UK grants.
          </li>
          <li className="privacy-policy-list-item">
            To improve the functionality and content of the Site.
          </li>
          <li className="privacy-policy-list-item">
            To comply with legal and regulatory requirements.
          </li>
        </ul>
        <h2 className="privacy-policy-subheading">
          Disclosure of Your Information
        </h2>
        <p className="privacy-policy-paragraph">
          We don't disclose your information to any third-party service. We are
          contractually bound to keep your information confidential and use it
          only for the purposes we have collected the information for.
        </p>
        <h2 className="privacy-policy-subheading">Data Retention</h2>
        <p className="privacy-policy-paragraph">
          We will retain your information for as long as necessary to fulfill
          the purposes described in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </p>

        <NavLink
          className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-[203px] mt-[20px] px-6 py-3 shadow-xl rounded-xl"
          smooth
          to="/contactus"
        >
          Contact Us
        </NavLink>
      </div>
    </>
  );
};

export default PrivacyPolicy;
