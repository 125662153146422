import React from "react";
import img from "../images/web.svg";
import img2 from "../images/app.svg";
import img3 from "../images/hosting.svg";
import img4 from "../images/consultation.svg";
import CH from "../images/centeral-heating.jpg";
import ser2 from "../images/ser2.jpg";
import WI from "../images/wall-insulation.jpg";
import LI from "../images/loft-sinsulation.jpg";
import LI1 from "../images/loft-insulation1.gif";
import { NavLink } from "react-router-dom";
const Services = (props) => {
  return (
    <>
      <div ref={props.ServicesRef}></div>
      <div id="services" ref={props.ServicesRef} className="bg-gray-100 py-12">
        <section data-aos="zoom-in-down" ref={props.fthRef}>
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-orange-700 uppercase font-bold">
              Services
            </h2>

            <div className="flex justify-center">
              <div className="w-24 border-b-4 border-orange-700"></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-orange-700">
              We are deeply committed to the growth and success of our
              customers.
            </h2>
          </div>

          <div className="px-12" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-1 gap-5 place-items-center ">
              <div className="bg-white transition-all ease-in-out duration-400 w-[70%] mobdiv overflow-hidden text-gray-700 hover:bg-gray-500  rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} /> */}
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    First Time Central Heating
                  </h2>
                  <p className="text-md font-medium">
                    • Owner and Tenant both are eligible for this scheme
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • If your home doesn't have heating, we can install central
                    heating for your property under the ECO4 scheme at no cost,
                    provided the occupants receive government benefits.
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • If there is no gas meter, we will consider alternative
                    options like an air source heat pump.
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • Owners or tenants living with old storage heaters
                    (electric or gas) and having low income or certain benefits
                    can apply, and our team will be in touch within the next few
                    minutes.
                  </p>
                  <br />

                  <p className="text-md font-medium" ref={props.arhpRef}>
                    • Application Process: Homeowners interested in upgrading to
                    central heating through the ECO4 Scheme are encouraged to
                    apply promptly. Once your application is received, our team
                    will swiftly assess your eligibility and inform you of the
                    available options for installing central heating in your
                    property free of cost.
                  </p>

                  <br />

                  <div className="flex justify-center w-[100%] ">
                    <NavLink
                      className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                      smooth
                      to="/eco4"
                    >
                      Apply Now
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="bg-white transition-all ease-in-out duration-400 w-[70%] mobdiv overflow-hidden text-gray-700 hover:bg-gray-500  rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* Image section (commented out) */}
                  {/* <img
      alt="card img"
      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
      src={img2}
    /> */}

                  {/* Title */}
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Air Source heat pump for electric property along with solar
                    panels
                  </h2>

                  {/* First paragraph */}
                  <p className="text-md font-medium">
                    • The UK government now offers a combined package of Air
                    Source Heat Pumps and solar panels. These systems utilize
                    external heat and solar energy, converting them into
                    reusable energy through a compressor to efficiently warm
                    homes.
                  </p>

                  <br />

                  {/* Second paragraph */}
                  <p className="text-md font-medium">
                    • Homeowners can benefit from a substantial grant of up to
                    £7,500 to encourage the adoption of this integrated
                    solution, supporting the creation of more sustainable and
                    energy-efficient living spaces.
                  </p>

                  <br />

                  {/* Third paragraph */}
                  <p className="text-md font-medium" ref={props.ncbrRef}>
                    • Proper insulation enhances the effectiveness of this
                    combined system, potentially leading to significant cost
                    savings during the colder months. This makes it an
                    economical and environmentally friendly heating solution.
                  </p>

                  {/* Call-to-action button */}
                  <div className="flex justify-center w-[100%]">
                    <NavLink
                      className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                      smooth
                      to="/eco4"
                    >
                      Apply Now
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400 w-[70%] mobdiv overflow-hidden text-gray-700 hover:bg-gray-500  rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                /> */}
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Replacement of Non Condensing & old inefficient Boilers
                  </h2>
                  <p className="text-md font-medium">
                    • <strong>Eco4 Scheme Eligibility:</strong> If your boiler
                    is over 10 years old, you may qualify for a free replacement
                    under the Eco4 Scheme initiated by the UK government. This
                    scheme aims to promote energy efficiency and reduce carbon
                    emissions by replacing old and inefficient boilers with
                    newer, more eco-friendly models.
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • <strong>Fully Funded Replacement:</strong> Under the Eco4
                    Scheme, all costs associated with the replacement of
                    qualifying boilers are covered by the UK government. This
                    includes the purchase and installation expenses, ensuring
                    that eligible participants can upgrade their boilers without
                    any financial burden.
                  </p>
                  <br />
                  <p className="text-md font-medium" ref={props.ewiRef}>
                    • <strong>Efficiency Assessment Process:</strong>{" "}
                    Determining the eligibility of your boiler for replacement
                    is a straightforward process. Simply send images of your
                    boiler to us, and our team will assess its efficiency and
                    condition. Based on this assessment, we will inform you
                    whether your boiler qualifies for replacement under the Eco4
                    Scheme or not.
                  </p>
                  <div className="flex justify-center w-[100%] ">
                    <NavLink
                      className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                      smooth
                      to="/eco4"
                    >
                      Apply Now
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="bg-white transition-all ease-in-out duration-400 w-[70%] mobdiv overflow-hidden text-gray-700 hover:bg-gray-500  rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3}
                /> */}
                  <h2 className="font-semibold my-4 text-2xl text-center ">
                    External Wall Insulation
                  </h2>
                  <p className="text-md font-medium">
                    • Owner and Tenant both are eligible for this scheme
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • <strong>Maximizing Energy Efficiency:</strong> Even if
                    your boiler is new, if your home lacks insulation, it could
                    still experience significant heat loss. To address this
                    issue and improve energy efficiency, homeowners are
                    encouraged to take advantage of the opportunity to enhance
                    their insulation.
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • <strong>Free Insulation Services:</strong> Through the UK
                    government's ECO4 Scheme, eligible homeowners can receive
                    external wall insulation and loft insulation completely free
                    of cost. These measures are aimed at reducing heat loss,
                    improving comfort levels, and ultimately lowering energy
                    bills for participants
                  </p>
                  <br />
                  <p className="text-md font-medium" ref={props.liRef}>
                    • <strong>Application Process:</strong> To determine
                    eligibility for the ECO4 Scheme and access the free
                    insulation services, homeowners are encouraged to apply
                    promptly. Upon receiving your application, our team will
                    swiftly assess your eligibility and inform you of the
                    available options for improving the insulation of your home.
                  </p>
                  <div className="flex justify-center w-[100%] ">
                    <NavLink
                      className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                      smooth
                      to="/eco4"
                    >
                      Apply Now
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400  w-[70%] mobdiv overflow-hidden text-gray-700 hover:bg-gray-500  rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                /> */}
                  <h2 className="font-semibold my-4 text-2xl text-center ">
                    Loft Insulation
                  </h2>
                  <p className="text-md font-medium">
                    • <strong>Optimizing Energy Efficiency:</strong> If your
                    loft lacks insulation or if the existing insulation is below
                    the recommended 200mm thickness, it's essential to address
                    this issue to improve the energy efficiency of your home.
                    Inadequate loft insulation can lead to significant heat
                    loss, resulting in higher energy consumption and increased
                    utility bills.
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • <strong>Government-Funded Insulation:</strong> Through the
                    UK government's initiative, homeowners are eligible to
                    receive free loft insulation services. This program covers
                    all expenses associated with upgrading or installing loft
                    insulation, ensuring that homeowners can enhance the energy
                    efficiency of their homes without any financial burden.
                  </p>
                  <br />
                  <p className="text-md font-medium">
                    • <strong>Effortless Application Process:</strong> To take
                    advantage of the free loft insulation offer, homeowners
                    simply need to apply for the program. Once your application
                    is processed, our team will arrange for the installation of
                    loft insulation at your property, ensuring that you can
                    enjoy the benefits of improved energy efficiency and reduced
                    heat loss without any upfront costs.
                  </p>
                  <br />

                  <div className="flex justify-center w-[100%] ">
                    <NavLink
                      className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                      smooth
                      to="/eco4"
                    >
                      Apply Now
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Services;
