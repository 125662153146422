import React from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { app } from "./Firebase";
import NavBar from "./Navbar/NavBar";

const { TextArea } = Input;

const ContactUs = () => {
  const onFinish = async (values) => {
    try {
      const db = getFirestore(app);
      values.dateofBirth = values.dateofBirth.toDate();
      const docRef = await addDoc(collection(db, "contactus"), values);
      console.log("Document ID:", docRef.id);
      message.success("Form submitted successfully!");
      console.log("Form submitted successfully!");
    } catch (error) {
      console.error(error);
      message.error("Failed to submit form.");
      console.error("Failed to submit form.");
    }
  };

  return (
    <>
      <NavBar />
      <div className="eco-container " id="contactus">
        <div className="form-box mt-20 mb-10">
          <div className="formheading">
            <h3>Kindly Fill the form with your details</h3>
          </div>
          <Form name="signup" onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                { required: true, message: "Please enter your full name" },
              ]}
            >
              <Input placeholder="Your Name" />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="number"
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input type="number" placeholder="Phone Number" />
            </Form.Item>

            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please enter your address" }]}
            >
              <Input placeholder="Address" />
            </Form.Item>

            {/* Add TextArea for Notes */}
            <Form.Item
              label="Any Note for Us"
              name="notes"
              rules={[{ required: false }]} // Optional field
            >
              <TextArea placeholder="Additional Notes" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="frmbtn">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
