import React from "react";
import img from "../images/boilers.jpg";
import UKMAP from "../components/UKMAP.jpg";
import epc from "../components/EPCRATING.png";
import eco4 from "./eco4pic.jpg";
import eco4mob from "./eco4mob.jpg";

import { NavLink } from "react-router-dom";
import { useRef } from "react";

const Intro = ({ eligibilitySectionRef }) => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img
              alt="card img"
              className="rounded-t float-right eco4pic"
              src={eco4}
            />
            <img
              alt="card img"
              className="rounded-t float-right eco4mobpic"
              src={eco4mob}
            />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-orange-700 font-bold">
              You may be eligible for a boiler grant through the UK government's
              ECO scheme.
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold schememob">
                Under the Energy Company Obligation (ECO), a qualifying benefit
                recipient with a boiler aged 20 years or more may be eligible
                for grant funding towards acquiring a new A-rated boiler as part
                of the UK government's initiative. These grants, provided by UK
                energy companies, are entirely free and require no repayment.
              </p>
            </div>

            {/* <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                We take responsibility for building custom software solutions
                that caters for automation of your business processes and
                improve efficiency.
              </p>
            </div> */}
            <button
              onClick={() =>
                eligibilitySectionRef.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
              className="text-white border-none outline-none  bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
              rel="noopener noreferrer" // recommended for security reasons
            >
              Check your eligibility
              <svg
                className="w-4 h-4 ml-1 group-hover:translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={UKMAP} />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-orange-700 font-bold">
              ECO4 Scheme from UK Government
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold schememob">
                In the UK, there's a program called the ECO4 Scheme that helps
                families with low incomes reduce their energy bills, use energy
                more efficiently, and have a smaller impact on the environment.
                If your home has an Energy Performance Certificate (EPC) rating
                of D or lower, you may qualify for assistance through the ECO4
                Government Funded Scheme. The goal of the ECO4 Scheme is to make
                homes more comfortable during cold weather by providing
                energy-efficient heating systems. Check if you qualify for the
                ECO4 Scheme now
              </p>
            </div>
            <NavLink to="/grants">
              <button
                onClick={() =>
                  eligibilitySectionRef.current.scrollIntoView({
                    behavior: "smooth",
                  })
                }
                className="text-white border-none outline-none  bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
                rel="noopener noreferrer" // recommended for security reasons
              >
                Learn more about ECO4 Scheme
                <svg
                  className="w-4 h-4 ml-1 group-hover:translate-x-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={epc} />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-orange-700 font-bold">
              EPC Calculations
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                A household with a low EPC rating (D, E, F, or G) will consume
                more fuel, produce more carbon emissions, and pay higher energy
                costs. Our goal is to enhance your property's overall energy
                efficiency, leading to a higher Energy Performance Certificate
                (EPC) rating. Upgrades include insulation in the floors, walls,
                and roofs to reduce heat loss. Consider upgrading to a
                condensing boiler or any other energy-efficient heating system.
                Invest in an ECO4 certified heating unit to improve your home's
                value and increase its energy efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
