import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import DemoProduct from "./pages/DemoProduct";

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import Eco4 from "./components/Eco4";
import NavBar from "./components/Navbar/NavBar";
import Aboutus from "./components/Aboutus";
import ContactUs from "./components/ContactUs";
import ECO4Grants from "./components/ECO4Grants";
import Footer from "./components/Footer";
import FirstTimeHeating from "./components/FirstTimeHeating";
import ExternalWall from "./components/ExternalWall";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  useDocTitle("Eco Heating Hub");
  const ServicesRef = useRef(null);
  const fthRef = useRef(null);
  const ewiRef = useRef(null);
  const ncbrRef = useRef(null);
  const arhpRef = useRef(null);
  const iwiRef = useRef(null);
  const uiRef = useRef(null);
  const liRef = useRef(null);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  fthRef={fthRef}
                  iwiRef={iwiRef}
                  ewiRef={ewiRef}
                  ncbrRef={ncbrRef}
                  arhpRef={arhpRef}
                  uiRef={uiRef}
                  liRef={liRef}
                  ServicesRef={ServicesRef}
                />
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} />
            <Route path="/eco4" element={<Eco4 />} />
            <Route path="/grants" element={<ECO4Grants />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />

            {/* <Route
              path="/firsttimecentralheating"
              element={<FirstTimeHeating />}
            /> */}
            {/* <Route path="/ExternalWall" element={<ExternalWall />} /> */}
          </Routes>
          <Footer
            fthRef={fthRef}
            iwiRef={iwiRef}
            ewiRef={ewiRef}
            ncbrRef={ncbrRef}
            arhpRef={arhpRef}
            uiRef={uiRef}
            liRef={liRef}
          />
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
