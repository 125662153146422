import React from "react";
import NavBar from "./Navbar/NavBar";
import { Link, NavLink } from "react-router-dom";

const Aboutus = () => {
  return (
    <div>
      <NavBar />
      <div className="aboutcontainer">
        <h1>About Us</h1>
        <p>
          We are a group of dedicated professionals known as Warmhome Grants ,
          who provide eco4 services and we use our free energy grants to assist
          low-income individuals in saving money and enhancing their quality of
          life. Our support is consistent with the UK government's ECO4 Scheme.
          We sincerely believe that everyone should have access to affordable,
          green energy, and we're committed to seeing that through to the
          finish. Your new boiler will be installed correctly and to the highest
          standards if you collaborate with a network of gas safe trained
          specialists. Additionally, we provide a 100% satisfaction guarantee,
          so you can be certain that you're selecting the greatest choice
          available.
        </p>
        <h1>Why Choose Us?</h1>
        <div className="whyus">
          <h2>Streamlined Application Process:</h2>
          <p>
            Experience the ease of our application process as you complete our
            user-friendly form in just 60 seconds. We've optimized every step to
            ensure efficiency and simplicity, allowing you to swiftly submit
            your application without any hassle. Our intuitive platform guides
            you through the process, minimizing the time and effort required.
            Say goodbye to lengthy forms and tedious procedures; with us,
            applying is quick, convenient, and stress-free. Join countless
            satisfied applicants who have embraced our seamless application
            process and started their journey effortlessly. Apply now and take
            the first step towards your goals in no time!
          </p>
          <h2>Eco4 Scheme Grants: Fully Funded by the UK Government</h2>
          <p>
            Unlock financial support for eco-friendly initiatives with our Eco4
            Scheme grants, proudly backed by the UK government. These grants
            cover a wide range of projects aimed at promoting sustainability and
            environmental conservation across various sectors. From renewable
            energy installations to energy-efficient upgrades and eco-friendly
            innovations, our grants offer substantial funding opportunities to
            eligible applicants. By participating in the Eco4 Scheme, you not
            only receive financial assistance but also contribute to the
            nation's efforts in combating climate change and building a greener
            future. Join us in making a positive impact on the environment while
            benefiting from 100% government-funded grants. Apply now and embark
            on your eco-conscious journey today!
          </p>
          <NavLink
            className="text-white bg-orange-500 mb-6 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl mt-5"
            smooth
            to="/eco4"
          >
            Apply Now
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
