import React from "react";
import { NavLink } from "react-router-dom";
import { InstagramOutlined, FacebookOutlined } from "@ant-design/icons";
import Logoeco from "../components/Logowarm.PNG";

const Footer = (props) => {
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto h-auto px-4 sm:px-6 bg-orange-300 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className=" flex justify-between hellofooter gap-5 p-[100px]  md:py-12 border-t border-grey-200 ">
            <div className="w-[170px] flex flex-col items-center">
              <img src={Logoeco} alt="" />
              <h3 className="font-extrabold text-xl mt-4 ml-2">
                Eco Heating Hub
              </h3>
            </div>
            <div className="col-span-12  lg:col-span-1 ml-7 flex flex-col items-start NavLinkfooter">
              <div className="w-full flex justify-center">
                <h6 className="text-orange-700 text-xl w-[100px] h6 font-bold mb-4 ">
                  Links
                </h6>
              </div>

              <ul className="text-md w-[300px] ">
                <p
                  onClick={() => {
                    if (props.fthRef && props.fthRef.current) {
                      props.fthRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000]  cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  • First Time Central Heating
                </p>
                <p
                  onClick={() => {
                    if (props.ewiRef && props.ewiRef.current) {
                      props.ewiRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  • External Wall Insulation
                </p>
                <p
                  onClick={() => {
                    if (props.ncbrRef && props.ncbrRef.current) {
                      props.ncbrRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  • Non Condensing Boilers Replacement
                </p>

                <p
                  onClick={() => {
                    if (props.arhpRef && props.arhpRef.current) {
                      props.arhpRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  • Air Source heat pump
                </p>
                <p
                  onClick={() => {
                    if (props.ewiRef && props.ewiRef.current) {
                      props.ewiRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  • Internal Wall Insulation{" "}
                </p>
                <p
                  onClick={() => {
                    if (props.liRef && props.liRef.current) {
                      props.liRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  • UnderFloor Insulation{" "}
                </p>
                <li>
                  <p
                    onClick={() => {
                      if (props.liRef && props.liRef.current) {
                        props.liRef.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }}
                    className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    • Loft Insulation
                  </p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center">
              <NavLink
                className="px-4 font-extrabold text-[#000000] hover:text-orange-800 mt-4"
                smooth
                to="/eco4"
              >
                Apply Now
              </NavLink>
              <NavLink
                className="px-4 font-extrabold text-[#000000] hover:text-orange-800 mt-4"
                smooth
                to="/contactus"
              >
                Contact Us
              </NavLink>
              <NavLink
                className="px-4 font-extrabold text-[#000000] hover:text-orange-800 mt-4"
                smooth
                to="/privacypolicy"
              >
                Privacy Policy
              </NavLink>
            </div>
            <div>
              <div className="col-span-12 text-center ml-[140px] mobwid mx-auto lg:col-span-3 font-bold uppercase text-orange-600">
                <h2 className="font-[24px] text-black mt-[40px] socialNavLinksmt">
                  Social Media NavLinks
                </h2>
                <div className="text-md font-medium mb-6 mt-[40px]">
                  <InstagramOutlined
                    style={{ fontSize: "24px", marginRight: "10px" }}
                  />
                  <a href="https://www.instagram.com/warmhomegrants?igsh=MzY1NDJmNzMyNQ==">
                    Instagram
                  </a>
                </div>
                <div className="text-md font-medium mb-6 pr-[6px]">
                  <FacebookOutlined
                    style={{ fontSize: "24px", marginRight: "10px" }}
                  />
                  <a href="https://www.facebook.com/profile.php?id=61556707827029&mibextid=LQQJ4D?">
                    Facebook
                  </a>
                </div>
                <div className="mx-auto text-center mt-2">
                  <ul className="flex justify-center mb-4 md:mb-0"></ul>
                </div>
              </div>
            </div>
            {/* 1st block */}
            {/* <div className="col-span-8 sm:col-span-6 lg:col-span-4">
              <div className="box-border border-b-4 whitebox border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                <h3 className="font-bold text-4xl mb-4">WARMHOME GRANTS </h3>
                <div className="text-md font-medium text-gray-600"></div>
              </div>
            </div> */}

            {/* 2nd block */}
            {/* <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h6 className="text-orange-700 text-xl font-bold mb-4">NavLinkS</h6>
              <ul className="text-md w-[300px]">
                <p
                  onClick={() => {
                    if (props.fthRef && props.fthRef.current) {
                      props.fthRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  First Time Central Heating
                </p>
                <p
                  onClick={() => {
                    if (props.ewiRef && props.ewiRef.current) {
                      props.ewiRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  External Wall Insulation
                </p>
                <p
                  onClick={() => {
                    if (props.ncbrRef && props.ncbrRef.current) {
                      props.ncbrRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Non Condensing Boilers Replacement
                </p>

                <p
                  onClick={() => {
                    if (props.arhpRef && props.arhpRef.current) {
                      props.arhpRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Air Source heat pump
                </p>
                <p
                  onClick={() => {
                    if (props.ewiRef && props.ewiRef.current) {
                      props.ewiRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Internal Wall Insulation{" "}
                </p>
                <li
                  onClick={() => {
                    if (props.liRef && props.liRef.current) {
                      props.liRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  UnderFloor Insulation{" "}
                </li>
                <li
                  onClick={() => {
                    if (props.liRef && props.liRef.current) {
                      props.liRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  }}
                  className="text-[#000000] cursor-pointer hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Loft Insulation{" "}
                </li>
              </ul>
            </div> */}
          </div>

          {/* <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                <HashNavLink to="#" className=" hover:text-gray-900">
                  ECO4 WarmhomeGrants
                </HashNavLink>
                . All rights reserved.
              </div>
            </div>
          </div> */}
        </div>
      </footer>
    </>
  );
};
export default Footer;
