import React from "react";
import NavBar from "./Navbar/NavBar";
import { NavLink } from "react-router-dom";

const ECO4Grants = () => {
  return (
    <>
      <NavBar />
      <div className="eco4-container">
        <h1 className="heading">
          ECO4 Grants: Enhancing Low-Income Housing and Public Health
        </h1>
        <div className="content">
          <h2 className="sub-heading">Understanding the ECO 4 Grant Scheme</h2>
          <p>
            Funded as part of the ECO4 Scheme, the ECO4 grants aim to enhance
            the Energy Performance Certificate (EPC) Ratings of 3.5 million
            homes in Great Britain. This initiative targets a reduction in
            carbon footprint, fuel expenses, and improvement in living
            conditions.
          </p>

          <h2 className="sub-heading">Exploring ECO 4 Grant Types</h2>
          <p>
            The ECO4 grants come in various types tailored to support low-income
            households:
          </p>
          <ul>
            <li>Boiler Grant</li>
            <li>Insulation Grant</li>
            <li>Heat Pump Grant</li>
            <li>Central Heating System Grant</li>
          </ul>

          <h2 className="sub-heading">ECO 4 Grant Benefits</h2>
          <p>
            Upgrading to newer, more efficient boilers through the ECO 4 grants
            offers several advantages:
          </p>
          <ul>
            <li>Meeting Minimum Energy Efficiency Standards (MEES)</li>
            <li>Enhanced living conditions and thermal comfort</li>
            <li>Access to grants and incentives</li>
            <li>Reduced environmental impact</li>
            <li>Avoidance of potential penalties</li>
            <li>Increased property value</li>
            <li>Lower energy bills</li>
          </ul>

          <h2 className="sub-heading">
            ECO 4: A Step Towards Environmental Preservation
          </h2>
          <p>
            The UK government's £4 billion ECO4 Scheme, operational until March
            2026, aims to achieve zero carbon emissions by 2050. This initiative
            seeks to mitigate environmental pollution and ensure a better
            quality of life for future generations.
          </p>

          <h2 className="sub-heading">Accessing Free Grants</h2>
          <p>
            Low-income households can benefit from the ECO4 Scheme by accessing
            free boiler, insulation, heat pump, and central heating system
            grants. These grants not only improve property value and EPC Ratings
            but also contribute to environmental preservation and cost savings.
          </p>

          <h2 className="sub-heading">How to Apply for ECO 4 Grants</h2>
          <p>
            Individuals receiving government benefits can easily check their
            eligibility for ECO 4 grants on our website or by contacting us by
            filling out the form. The application process is quick and
            straightforward, taking only a minute to determine eligibility.
          </p>

          <h2 className="sub-heading">Eligibility Criteria for ECO 4 Scheme</h2>
          <p>
            To qualify for ECO 4 grants, individuals must receive one of the
            specified government benefits, including but not limited to
            Income-Based Employment & Support Allowance, Pension Credit,
            Universal Credit, and Child Benefit.
          </p>

          <h2 className="sub-heading">Improving EPC Ratings</h2>
          <p>
            Upgrading boilers, insulation, heat pumps, and central heating
            systems significantly enhances energy efficiency, resulting in an
            improvement of 2-3 EPC Ratings. Don't miss out on this opportunity
            to upgrade and improve your home's efficiency.
          </p>
          <div className="pos">
            <NavLink
              className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-[200px] px-6 py-3 shadow-xl rounded-xl"
              smooth
              to="/eco4"
            >
              Apply Now
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ECO4Grants;
