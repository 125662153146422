import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import wall from "../images/wall-insulation.jpg";
import BannerImage1 from "./Banner1.jpg";
import BannerImage2 from "./Banner2.jpg";
import BannerImage3 from "./Banner3.jpg";
import mobBannerImage1 from "./mobanner1.jpg";
import mobBannerImage2 from "./mobanner2.jpg";
import mobBannerImage3 from "./mobbanner3.jpg";
const BannerSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [BannerImage1, BannerImage2, BannerImage3];
  const mobimages = [mobBannerImage1, mobBannerImage2, mobBannerImage3];

  return (
    <>
      <div className="slider">
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index} className="bannerdiv">
              <img
                src={image}
                alt={`Banner ${index + 1}`}
                className="bannerimg"
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="mobslider">
        <Slider {...sliderSettings}>
          {mobimages.map((image, index) => (
            <div key={index} className="mobbannerdiv">
              <img
                src={image}
                alt={`Banner ${index + 1}`}
                className="mobbannerimg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default BannerSlider;
