import React from "react";

const clientImage = {
  height: "10rem",
  width: "auto",
  mixBlendMode: "colorBurn",
};

const Clients = () => {
  return (
    <div className="mt-8 bg-gray-100">
      {/* <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-orange-700 uppercase font-bold">
            Essential Guidelines
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-orange-900"></div>
          </div>
        </div>

        <div className="px-12 " data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-1 gap-5 place-items-center  ">
            <div className="bg-white transition-all ease-in-out duration-400 w-[70%] mobdiv p-[30px] overflow-hidden text-gray-700  mb-2 rounded-lg shadow-2xl  group">
              <div>
                <ul>
                  <li className="my-3 text-base text-gray-600 font-semibold">
                    • You must have detailed knowledge and research about your
                    job and well prepared about the general questions like:
                  </li>
                  <div className="">
                    <p>a. To whom you are representing ?</p>
                    <p> b. What’s this Grant ?</p>
                    <p>c. Why we need to upgrade EPC ?</p>
                    <p>d. Would you claim any hidden charges after the job ?</p>
                    <p> e. How this grant will save our utility charges ?</p>
                  </div>

                  <li className="my-3 text-base text-gray-600 font-semibold">
                    • You must have information regarding famous brands of
                    boiler, its powers etc we install Wooster bosh 28di boiler
                    mostly.
                  </li>
                  <li className="my-3 text-base text-gray-600 font-semibold">
                    • You must represent yourself like a professional staff i.e
                    displaying company card, fully attentive towards customer
                    holding diary, pen, leaflets, mobile phone etc.
                  </li>
                  <li className="my-3 text-base text-gray-600 font-semibold">
                    • Deal customers friendly and humbly.
                  </li>
                  <li className="my-3 text-base text-gray-600 font-semibold">
                    • We are not supposed to attract the customers while making
                    fake and false promises.
                  </li>
                  <li className="my-3 text-base text-gray-600 font-semibold">
                    • You must have confidence that your work did not base on
                    daily wages and you can overcome your performance soon.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Clients;
