/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { NavLink } from "react-router-dom";

const Benefits = ({ eligibilitySectionRef }) => {
  return (
    <>
      <div ref={eligibilitySectionRef}></div>
      <div className="my-4 py-4" id="portfolio">
        <h2 className="my-2 text-center text-3xl text-orange-700 uppercase font-bold elegmob">
          Eligibility for Grants
        </h2>
        <div className="flex justify-center">
          <div className=" border-b-4 border-orange-900 mb-8 seemob">
            See if you have availed any one of the benefits below :
          </div>
        </div>
        <div className="w-[100%] flex flex-col items-center">
          <h4 className="w-[50%] text-start transmob">
            Transform your home with Eco4 Scheme Check to see if you qualify for
            the ECO4 Grant. Step towards the sustainable future Boiler older
            then 2005 Step towards the sustainable future Eligible Government
            Benefits Recipient:
          </h4>
          <br /> <br />
          <div className="w-[50%] text-start">
            <p className="text-md font-medium text-start">
              • Income-based Job seeker’s Allowance (JSA)
            </p>
            <p className="text-md font-medium text-start">
              • Income-related Employment & Support Allowance (ESA)
            </p>
            <p className="text-md font-medium text-start">
              • Income Support Allowance (ISA)
            </p>
            <p className="text-md font-medium text-start">
              • Working Tax Credit (WTC)
            </p>
            <p className="text-md font-medium text-start">
              • Child tax credit (CTC)
            </p>
            <p className="text-md font-medium text-start">
              • Universal Credit (UC)
            </p>
            <p className="text-md font-medium text-start">
              • Pension savings Credit
            </p>
            <p className="text-md font-medium text-start">
              • Income-based Job seeker’s Allowance (JSA)
            </p>
            <p className="text-md font-medium text-start">
              • Pension Guarantee Credit
            </p>
            <p className="text-md font-medium text-start">• Child benefit</p>
            <p className="text-md font-medium text-start">• Housing benefit</p>
          </div>
          <div className="flex justify-center w-[100%] ">
         
                    </div>
                    <br /> <br /> 
          
          <NavLink
             className="text-white bg-orange-500 hover:bg-orange-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
             smooth
             to="/eco4"
           >
             Check Eligibility
           </NavLink>
                  
        </div>
        {/* <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                  Universal Credit (UC)
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  You may be able to get Universal Credit if you’re on a low
                  income or need help with your living costs. You could be: out
                  of work working (including self-employed or part time) unable
                  to work, afor example because of a health condition
                </p>
                <div className="flex justify-center my-4">
                  <NavLink
                    to="/eco4"
                    className="text-white bg-orange-500 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                  >
                    Apply Now
                    <svg
                      className="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                  Child Tax Credit (CTC){" "}
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  Child Tax Credit is a benefit that helps with the costs of
                  raising a child if you're on a low income.
                </p>
                <div className="flex justify-center my-4">
                  <NavLink
                    to="/eco4"
                    className="text-white bg-orange-500 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                  >
                    Apply Now
                    <svg
                      className="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </NavLink>
                </div>

                
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                  Child Benefit (Income Related){" "}
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  Child Benefit is a tax-free payment made to eligible parents
                  or guardians in the United Kingdom to help with the costs of
                  raising a child. It is available to anyone responsible for a
                  child, regardless of whether they are working or not.
                </p>
                <div className="flex justify-center my-4">
                  <NavLink
                    target="_blank"
                    to="/eco4"
                    className="text-white bg-orange-500 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                  >
                    Apply Now
                    <svg
                      className="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                  Working Tax Credit (WTC)
                </h4>
                <p className="text-md font-medium leading-5 h-auto md:h-48">
                  The Working Tax Credit (WTC) is a state benefit offered to
                  individuals living in the United Kingdom who work and have a
                  low income. You must already be receiving the Child Tax Credit
                  to qualify for the WTC. The Working Tax Credit (WTC) is
                  available for individuals aged 25 and above or with a
                  qualifying disability.
                </p>
                <div className="flex justify-center my-4">
                  <NavLink
                    to="/eco4"
                    className="text-white bg-orange-500 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                  >
                    Apply Now
                    <svg
                      className="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      
      </div>
    </>
  );
};

export default Benefits;
